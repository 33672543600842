<template>
  <div style="padding-bottom:44px">
    <van-field label="序列号" v-model="ysy001_Step2.serialNumber" placeholder="请输入序列号">
      <template #right-icon>
        <van-tag type="warning">{{jieMa}}</van-tag>
      </template>
    </van-field>
    <van-cell title="时间运行检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step2.shiJianYunXing" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="蜂鸣器检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step2.fengMingQi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="按键检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step2.anJian" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="液晶显示检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step2.yeJingXianShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="继电器输出检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step2.jiDianQiShuChu" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="电机正反转检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step2.dianJiZhengFanZhuan" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="无线模块检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step2.wuXianMoKuai" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="温度测量检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step2.wenDuCeLiang" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="左右限位检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step2.zuoYouXianWei" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="温度实际控制检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step2.wenDuShiJiKongZhi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="无线联网检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step2.wuXianLianWang" shape="square"></van-checkbox>
      </template>
    </van-cell>

    <van-field label="备注" v-model="ysy001_Step2.remark"> </van-field>
    <el-button type="primary" class="footer1" icon="el-icon-view" circle @click="handlePicClick"></el-button>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step2" round block :disabled="$parent.ysy001_SignNumber.status!=0" @click="handleSave">保存</van-button>
      <van-button type="danger" v-if="$parent.ysy001_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.ysy001_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>

    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  props: ["signNumber"],
  data() {
    return {
      ysy001_Step2: {},
      jieMa: "",
    };
  },
  methods: {
    handlePicClick() {
      ImagePreview(["./images/YSY001_Produce.png"]);
    },
    getYSY001_Step2() {
      let that = this;
      that.axios.post("/YSY001/GetYSY001_Step2", { onlyText: that.signNumber }).then(function (response) {
        that.ysy001_Step2 = response.data.data;
      });
    },
    handleSave() {
      let that = this;
      that.axios.all([that.save_YSY001_Step2(), that.registerSerialNumber()]).then(
        that.axios.spread(function (response1, response2) {
          that.ysy001_Step2 = response1.data.data;
          if (response1.data.code == 201) {
            that.$notify({ type: "warning", message: response1.data.msg });
          } else if (response2.data.code == 201) {
            that.$notify({ type: "warning", message: response2.data.msg });
          } else {
            that.$notify({ type: "success", message: response1.data.msg });
          }
          if (response2.data.code == 101) {
            that.jieMa = response2.data.msg;
          }
        })
      );
    },
    save_YSY001_Step2() {
      let that = this;
      return that.axios.post("YSY001/UpdateYSY001_Step2", that.ysy001_Step2);
    },
    registerSerialNumber() {
      let that = this;
      return that.axios.post("Base_Device/Register", {
        productName: "wifi_0.01延伸度测定仪",
        productId: 1079,
        serialNumber: that.ysy001_Step2.serialNumber,
        deviceType: "YSY001",
        companyName: "",
        ifPrint: false,
      });
    },
  },
  mounted() {
    let that = this;
    that.getYSY001_Step2();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
.footer1 {
  position: fixed;
  left: 10px;
  bottom: 50px;
}
</style>