<template>
  <div>
    <van-cell title="时间运行检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step1.shiJianYunXing" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="蜂鸣器检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step1.fengMingQi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="按键检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step1.anJian" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="液晶显示检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step1.yeJingXianShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="继电器输出检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step1.jiDianQiShuChu" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="电机正反转检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step1.dianJiZhengFanZhuan" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="无线模块检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step1.wuXianMoKuai" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="温度测量检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step1.wenDuCeLiang" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="左右限位检测">
      <template #right-icon>
        <van-checkbox v-model="ysy001_Step1.zuoYouXianWei" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-field label="备注" v-model="ysy001_Step1.remark"> </van-field>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step1" :disabled="$parent.ysy001_SignNumber.status!=0" round block @click="save_YSY001_Step1">保存</van-button>
      <van-button type="danger" v-if="$parent.ysy001_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.ysy001_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["signNumber"],
  data() {
    return {
      ysy001_Step1: {},
    };
  },
  methods: {
    getYSY001_Step1() {
      let that = this;
      that.axios.post("/YSY001/GetYSY001_Step1", { onlyText: that.signNumber }).then(function (response) {
        that.ysy001_Step1 = response.data.data;
      });
    },
    save_YSY001_Step1() {
      let that = this;
      that.axios.post("YSY001/UpdateYSY001_Step1", that.ysy001_Step1).then(function (response) {
        that.ysy001_Step1 = response.data.data;
        that.$notify({ type: "success", message: response.data.msg });
      });
    },
  },
  mounted() {
    let that = this;
    that.getYSY001_Step1();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
</style>